import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import waterVertexShader from './shaders/water/vertex.glsl'
import waterFragmentShader from './shaders/water/fragment.glsl'
import { VRButton } from 'three/examples/jsm/webxr/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { XRHandModelFactory } from 'three/examples/jsm/webxr/XRHandModelFactory.js';



let container;
let camera, scene, renderer;
let controller1, controller2;
let controllerGrip1, controllerGrip2;
let raycaster;

const intersected = [];
const tempMatrix = new THREE.Matrix4();

let group;


init();

function init() {

    container = document.createElement('div');
    document.body.appendChild(container);
    container.addEventListener( 'click', function () {

        video.play();

    } );

    const video = document.getElementById( 'video' );
    video.play();
    video.setAttribute("crossorigin", "anonymous"); 
    const videoCinema = new THREE.VideoTexture( video )

    scene = new THREE.Scene();
    scene.background = 0x000000
    camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 30);
    camera.position.set(0, 0, 3);

    const controls = new OrbitControls(camera, container);
    controls.minDistance = 0;
    controls.maxDistance = 8;

    

    const light = new THREE.DirectionalLight(0xffffff);
    light.position.set(0, 6, 0);
    scene.add(light);

    group = new THREE.Group();
    scene.add(group);

    const TelaCinema = new THREE.PlaneGeometry(4, 2.25, 10)
    const TelaMaterial = new THREE.MeshBasicMaterial()
    TelaMaterial.map = videoCinema
    const Tela = new THREE.Mesh(TelaCinema, TelaMaterial)
    Tela.position.y = 1.5
    Tela.position.z = -3.
    scene.add(Tela)

    // const geometries = [
    //     new THREE.BoxGeometry(0.2, 0.2, 0.2),
    //     new THREE.ConeGeometry(0.2, 0.2, 64),
    //     new THREE.CylinderGeometry(0.2, 0.2, 0.2, 64),
    //     new THREE.IcosahedronGeometry(0.2, 8),
    //     new THREE.TorusGeometry(0.2, 0.04, 64, 32)
    // ];

    // for (let i = 0; i < 50; i++) {

    //     const geometry = geometries[Math.floor(Math.random() * geometries.length)];
    //     const material = new THREE.MeshStandardMaterial({
    //         color: Math.random() * 0xffffff,
    //         roughness: 0.7,
    //         metalness: 0.0
    //     });

    //     const object = new THREE.Mesh(geometry, material);

    //     object.position.x = Math.random() * 8 - 2;
    //     object.position.y = Math.random() * 7 - 2;
    //     object.position.z = Math.random() * 3 - 2;

    //     object.rotation.x = Math.random() * 2 * Math.PI;
    //     object.rotation.y = Math.random() * 2 * Math.PI;
    //     object.rotation.z = Math.random() * 2 * Math.PI;

    //     object.scale.setScalar(Math.random() + 0.5);

    //     group.add(object);

    // }

    //
    scene.fog = new THREE.Fog(0x000000, 3, 15);


    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    renderer.xr.enabled = true;
    container.appendChild(renderer.domElement);

    document.body.appendChild(VRButton.createButton(renderer));

    // controllers

    controller1 = renderer.xr.getController(0);
    controller1.addEventListener('selectstart', onSelectStart);
    controller1.addEventListener('selectend', onSelectEnd);
    scene.add(controller1);

    controller2 = renderer.xr.getController(1);
    controller2.addEventListener('selectstart', onSelectStart);
    controller2.addEventListener('selectend', onSelectEnd);
    scene.add(controller2);

    const controllerModelFactory = new XRControllerModelFactory();

    controllerGrip1 = renderer.xr.getControllerGrip( 0 );
    controllerGrip1.add( controllerModelFactory.createControllerModel( controllerGrip1 ) );
    scene.add( controllerGrip1 );

    controllerGrip2 = renderer.xr.getControllerGrip( 1 );
    controllerGrip2.add( controllerModelFactory.createControllerModel( controllerGrip2 ) );
    scene.add( controllerGrip2 );

	//

	const geometry = new THREE.BufferGeometry().setFromPoints( [ new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 0, - 1 ) ] );

	const line = new THREE.Line( geometry );
	line.name = 'line';
	line.scale.z = 5;

	controller1.add( line.clone() );
	controller2.add( line.clone() );

	raycaster = new THREE.Raycaster();

	//


    raycaster = new THREE.Raycaster();

    //

    window.addEventListener('resize', onWindowResize);

}


/**
* Water
*/
    // Geometry
    const waterGeometry = new THREE.PlaneGeometry(50, 50, 512, 512)

    // Colors
    const depthColor = '#143648'
    const surfaceColor = '#000000'

    // Material
    const waterMaterial = new THREE.ShaderMaterial({
        vertexShader: waterVertexShader,
        fragmentShader: waterFragmentShader,
        uniforms:
        {
            uTime: { value: 0 },

            uBigWavesElevation: { value: 0.08 },
            uBigWavesFrequency: { value: new THREE.Vector2(4, 4.2) },
            uBigWavesSpeed: { value: 0.75 },

            uSmallWavesElevation: { value: 0.445 },
            uSmallWavesFrequency: { value: 3 },
            uSmallWavesSpeed: { value: 0.2 },
            uSmallIterations: { value: 4 },

            uDepthColor: { value: new THREE.Color(0x143648) },
            uSurfaceColor: { value: new THREE.Color(0x000000) },
            uColorOffset: { value: 0.08 },
            uColorMultiplier: { value: 5 },
            fogColor: { type: "c", value: scene.fog.color },
            fogNear: { type: "f", value: scene.fog.near },
            fogFar: { type: "f", value: scene.fog.far }
        },
        fog: true
    })


    // Mesh
    const water = new THREE.Mesh(waterGeometry, waterMaterial)
    water.rotation.x = - Math.PI * 0.5
    scene.add(water)

    //

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

}

function onSelectStart(event) {

    const controller = event.target;

    const intersections = getIntersections(controller);

    if (intersections.length > 0) {

        const intersection = intersections[0];

        const object = intersection.object;
        object.material.emissive.b = 1;
        controller.attach(object);

        controller.userData.selected = object;

    }

}

function onSelectEnd(event) {

    const controller = event.target;

    if (controller.userData.selected !== undefined) {

        const object = controller.userData.selected;
        object.material.emissive.b = 0;
        group.attach(object);

        controller.userData.selected = undefined;

    }


}

function getIntersections(controller) {

    tempMatrix.identity().extractRotation(controller.matrixWorld);

    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, - 1).applyMatrix4(tempMatrix);

    return raycaster.intersectObjects(group.children, false);

}

function intersectObjects(controller) {

    // Do not highlight when already selected

    if (controller.userData.selected !== undefined) return;

    const intersections = getIntersections(controller);

    if (intersections.length > 0) {

        const intersection = intersections[0];

        const object = intersection.object;
        object.material.emissive.r = 1;
        intersected.push(object);

    }

}
const clock = new THREE.Clock()


function cleanIntersected() {

    while (intersected.length) {

        const object = intersected.pop();
        object.material.emissive.r = 0;

    }

}

//

const Animate = () => {

    renderer.setAnimationLoop(render);

}
Animate()

function render() {

    cleanIntersected();
    const elapsedTime = clock.getElapsedTime()
    waterMaterial.uniforms.uTime.value = elapsedTime

    intersectObjects(controller1);
    intersectObjects(controller2);

    renderer.render(scene, camera);

}

